import styled from "styled-components"
// import storeThumb1 from "../../assets/images/store/store_thumb_1.png";
// import storeThumb2 from "../../assets/images/store/store_thumb_2.png";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
// import { Tab } from "react-bootstrap";
import { callApi } from "../../util/HttpService";
import { useTranslation } from "react-i18next";
import MetaTag from "../../layout/MetaTag";
import Search from "@mui/icons-material/Search";

const Container = styled.div`
    .tab-pane { display: none; }
    .tab-pane.active { display: block; }
    .fade { opacity: 0; transition: opacity 0.2s ease-in-out; }
    .fade.show { opacity: 1; }
    @media screen and (max-width: 767px) {
        .title-tab {font-size:28px;margin-bottom:20px;}
        .title-tab .search {position: relative;margin-top:30px;}
        .store-list {grid-template-columns: repeat(1, 1fr);}
        .store-item .store-thumb {height:200px;}
        .root_daum_roughmap .wrap_map {height:250px !important;}
    }
    .btn.btn-black {
        display:inline-block; border-radius:4px; background:#343434; 
        color:#fff; text-align:center; padding:12px 40px; line-height:1;
    }
    .disabled {opacity:.3;}
`;
const TitleTap = styled.div`
    font-size:36px; font-weight:600; margin-bottom:40px;position:relative;
    svg {
        font-size: 1.3em;
        color: inherit;
    }
    .title-tab-btn { color:#C6C6C6; }
    .title-tab-btn.active {color:#333D4B;}
    .btn-search { color: #000; }
    .search {
        position:absolute;right:0;top:-62px; // 원래 top:0이었음.
    }
    // 따로 추가한 부분
    @media screen and (max-width: 767px) {
        .search {
            position:absolute;right:0;top:0;
        }
    }
`;
const StoreList = styled.ul`
    display:grid;grid-template-columns: repeat(2, 1fr);gap:20px;
    margin-bottom:40px;
`;
const StoreItem = styled.li`
    border-radius: 18px;border: 1px solid rgba(255, 255, 255, 0.20);
    background: linear-gradient(57deg, #92DEFF 0.31%, #9D65D9 99.69%);
    position:relative;overflow:hidden;
    .store-thumb { width:100%;height:100%;object-fit:cover; }
    .store-title {
        background-color:#343434;display:block;text-align:center;
        position:absolute;bottom:0;left:0;right:0;color:#fff;padding:12px;
        line-height:1.4;font-weight:600;
    }
    & > div {cursor: pointer;}
`;

export default function Store() {

    const defaultSearchData = {
        blbdCd: "STGD",
        pstsTitl: "",
        cnncIpAddr: "",
        page: 0,
        size: 4,
    };
    const { t } = useTranslation();
    // const [activeTab, setActiveTab] = useState("kr");
    const navigate = useNavigate();
    const [searchData, setSearchData] = useState(defaultSearchData);
    const [streList, setStreList] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        getStreList(currentPage);
    }, [currentPage]);

    const getStreList = (page) => {
        callApi("GET", { ...searchData, page }, "/web/api/public/stre-page")
            .then(res => {
                // console.log(res);
                if (page === 0) {
                    setStreList(res.content);
                } else {
                    setStreList((prevList) => [...prevList, ...res.content]);
                }
                setTotalPages(res.totalPages);
            })
            .catch(err => {
                console.error(err);
            });
    };

    const handleLoadMore = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    // const handleTabClick = (name) => {
    //     setActiveTab(name);
    // };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleFormDataChange = (e) => {
        const { name, value } = e.target;
        setSearchData((prevData) => ({
            ...prevData,
            [name]: value,
        }))
    };

    const handleInputPwdKeyDown = (e) => {
        if (e.keyCode === 13) {
            setCurrentPage(0);
            getStreList(0);
        }
    };

    const handleLinkClick = (pstsSqno) => {
        navigate(`/store/store-view/${pstsSqno}`);
        const params = { ...searchData, pstsSqno: pstsSqno };
        callApi("POST", params, `/web/api/public/psts-view-count`)
            .then(res => {
                // console.log(res);
            })
            .catch(err => {
                console.error(err);
                alert("접근 요청 실패: 네트워크를 확인해주세요.");
            })
    };

    // 현재 페이지의 데이터가 마지막 페이지인지 확인
    const isLastPage = currentPage >= totalPages - 1;

    return (
        <>
            <MetaTag
                title="店舗案内 - Photomag"
                description="Photomag店にお越しいただき、感覚的なマガジンスタイルの写真をご体験いただけます。 特別な瞬間を撮ることができる様々なフォトアイテムとインテリアで、写真の中の瞬間をより一層輝かせてくれます。"
                preload={`${process.env.REACT_APP_CDN_URL}/store/sub_header_bg.webp`}
            />
            <Container className="container">
                <section>
                    <div className="page-header">
                        <div className="page-header-bg">
                            <img
                                srcSet={`
                                    ${process.env.REACT_APP_CDN_URL}/store/sub_header_bg-320.webp 320w,
                                    ${process.env.REACT_APP_CDN_URL}/store/sub_header_bg-768.webp 768w,
                                    ${process.env.REACT_APP_CDN_URL}/store/sub_header_bg-1400.webp 1400w,
                                    ${process.env.REACT_APP_CDN_URL}/store/sub_header_bg.webp 1920w
                                `}
                                sizes="(max-width: 320px) 320px, 
                                    (max-width: 768px) 768px, 
                                    (max-width: 1400px) 1400px, 
                                    1920px"
                                src={`${process.env.REACT_APP_CDN_URL}/store/sub_header_bg.webp`}
                                alt="Photomag store logo"
                                width={1531}
                                height={440}
                                loading="eager"
                            />
                        </div>
                        <div className="inner">
                            <h2 className="page-title">{t("common.store")}</h2>
                            <p className="page-text"></p>
                        </div>
                    </div>
                    <div className="inner">
                        <TitleTap className="title-tab">
                            {/* 지금 일본 매장밖에 없어서 한국 매장이 생기면 그때 추가 */}
                            {/* <button
                            className={`title-tab-btn ${activeTab === 'kr' ? 'active' : ''}`}
                            name="kr"
                            onClick={() => handleTabClick('kr')}
                        >
                            한국
                        </button>
                        ㅣ
                        <button
                            className={`title-tab-btn ${activeTab === 'jp' ? 'active' : ''}`}
                            name="jp"
                            onClick={() => handleTabClick('jp')}
                        >
                            일본
                        </button> */}
                            <div className="search">
                                <input
                                    type="text"
                                    name="pstsTitl"
                                    value={searchData.pstsTitl}
                                    onChange={handleFormDataChange}
                                    className="input"
                                    placeholder={t("boardPage.pstsTitlInput")}
                                    onKeyDown={handleInputPwdKeyDown}
                                />
                                <button className="btn-search" onClick={() => { getStreList() }} aria-label="store search">
                                    <Search />
                                </button>
                            </div>
                        </TitleTap>
                        <div className="tab-container">
                            <StoreList className="store-list">
                                {streList.map((data, idx) => (
                                    <StoreItem className="store-item" key={data.pstsSqno} onClick={() => handleLinkClick(data.pstsSqno)}>
                                        <div onClick={() => navigate(`/store/store-view/${data.pstsSqno}`)}>
                                            <img className="store-thumb" src={`https://photomag-board-upload-file.s3.amazonaws.com/${data.imgPathAddr}/${data.imgFileNm}`} alt="Photomag store" />
                                            <strong className="store-title">{data.pstsTitl}</strong>
                                        </div>
                                    </StoreItem>
                                ))}
                            </StoreList>
                            <div className="ta-center">
                                <button
                                    className={`btn btn-black ${isLastPage ? "disabled" : ""}`}
                                    onClick={handleLoadMore}
                                    disabled={isLastPage}
                                >
                                    {t("boardPage.more")}
                                </button>
                            </div>
                        </div>
                        {/* <Tab.Container activeKey={activeTab}>
                        <Tab.Content>
                            <Tab.Pane eventKey="kr">
                                <div className="tab-container">
                                    <StoreList className="store-list">
                                        <StoreItem className="store-item">
                                            <Link to="/store/store-view/0">
                                                <img className="store-thumb" src={storeThumb1} alt="Photomag storeThumb1" />
                                                <strong className="store-title">일본 오사카 무인사진관 ‘Hallyu Studio’ 오픈</strong>
                                            </Link>
                                        </StoreItem>
                                        <StoreItem className="store-item">
                                            <Link to="/store/store-view/0">
                                                <img className="store-thumb" src={storeThumb2} alt="Photomag storeThumb2" />
                                                <strong className="store-title">일본 오사카 무인사진관 ‘Photomag’ 오픈</strong>
                                            </Link>
                                        </StoreItem>
                                        <StoreItem className="store-item">
                                            <Link to="/store/store-view/0">
                                                <img className="store-thumb" src={storeThumb1} alt="Photomag storeThumb1" />
                                                <strong className="store-title">일본 오사카 무인사진관 ‘Hallyu Studio’ 오픈</strong>
                                            </Link>
                                        </StoreItem>
                                        <StoreItem className="store-item">
                                            <Link to="/store/store-view/0">
                                                <img className="store-thumb" src={storeThumb2} alt="Photomag storeThumb2" />
                                                <strong className="store-title">일본 오사카 무인사진관 ‘Photomag’ 오픈</strong>
                                            </Link>
                                        </StoreItem>
                                    </StoreList>
                                    <div className="ta-center">
                                        <button className="btn btn-black">더보기</button>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="jp">
                                <div className="tab-container">
                                    <StoreList className="store-list">
                                        <StoreItem className="store-item">
                                            <Link to="/store/store-view/0">
                                                <img className="store-thumb" src={storeThumb1} alt="Photomag storeThumb1" />
                                                <strong className="store-title">2 일본 오사카 무인사진관 ‘Hallyu Studio’ 오픈</strong>
                                            </Link>
                                        </StoreItem>
                                        <StoreItem className="store-item">
                                            <Link to="/store/store-view/0">
                                                <img className="store-thumb" src={storeThumb2} alt="Photomag storeThumb2" />
                                                <strong className="store-title">일본 오사카 무인사진관 ‘Photomag’ 오픈</strong>
                                            </Link>
                                        </StoreItem>
                                        <StoreItem className="store-item">
                                            <Link to="/store/store-view/0">
                                                <img className="store-thumb" src={storeThumb1} alt="Photomag storeThumb1" />
                                                <strong className="store-title">일본 오사카 무인사진관 ‘Hallyu Studio’ 오픈</strong>
                                            </Link>
                                        </StoreItem>
                                        <StoreItem className="store-item">
                                            <Link to="/store/store-view/0">
                                                <img className="store-thumb" src={storeThumb2} alt="Photomag storeThumb2" />
                                                <strong className="store-title">일본 오사카 무인사진관 ‘Photomag’ 오픈</strong>
                                            </Link>
                                        </StoreItem>
                                    </StoreList>
                                    <div className="ta-center">
                                        <button className="btn btn-black">더보기</button>
                                    </div>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container> */}
                    </div>
                </section>
            </Container>
        </>
    )
}