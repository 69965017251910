import styled from "styled-components";
import logo from "../assets/images/layout/logo.svg";
import { useTranslation } from "react-i18next";
import CallIcon from '@mui/icons-material/Call';
import StoreIcon from '@mui/icons-material/Store';
import Assignment from '@mui/icons-material/Assignment';
import LocationOn from "@mui/icons-material/LocationOn";

const FooterWrap = styled.div`
    background-color:#212121;
    border-top:1px solid rgba(255, 255, 255, 0.60);
    padding:60px 0 50px;
    .ft-logo {
        height:25px;
        margin-bottom:30px;
    }
    .ft-company {
        margin-bottom:30px;
        color:#fff;
        font-weight:300;
        font-size:1.4rem;
        li {
            margin-bottom:12px;
            display:flex;
            gap:8px;
            align-items:start;
            line-height: 1.4;
            &:last-child {
                margin-bottom:0;
            }
        }
        svg {
            font-size: 1.3em;
            color: inherit;
        }
    }
    .ft-copyright {
        color:#cacaca;
        font-weight:300;
        font-size:1.4rem;
    }
    .block {
        display: block !important;
        .mr-8 { margin-right: 8px; }
    }
    @media screen and (max-width: 767px) {
        .ft-copyright {
            font-size: 1.3rem;
        }
    }
`;

function Footer() {
    const { t, i18n } = useTranslation();
    const isJp = i18n.language === 'jp';
    return (
        <FooterWrap className="footer-wrap">
            <div className="inner">
                <footer>
                    <img className="ft-logo" src={logo} alt="Photomag" width={118} height={25} />
                    <ul className="ft-company">
                        <li>
                            <CallIcon />{t("footer.call")} 03-6380-3765 / 1000@handepa.jp</li>
                        <li className="block">
                            <StoreIcon style={{ marginRight: "8px" }} />{t("footer.corpNm1")}
                            <span style={{ display: "block", marginLeft: isJp ? "71px" : "65.5px" }}>
                                {t("footer.corpNm2")}
                            </span>
                        </li>
                        <li>
                            <Assignment />{t("footer.corpIdx")} 1 0111 0106 2902
                        </li>
                        <li className="block">
                            <LocationOn style={{ marginRight: "8px" }} />{t("footer.headOffice")}
                            <span style={{ display: "block", marginLeft: isJp ? "57.5px" : "53.7px" }}>
                                {t("footer.branch")}
                            </span>
                        </li>
                    </ul>
                    <p className="ft-copyright">Copyright ⓒ Hallyu Department Store All rights reserved</p>
                </footer>
            </div>
        </FooterWrap>
    )
}
export default Footer;