import { Helmet } from "react-helmet-async";
import { createGlobalStyle } from "styled-components";
import { useTranslation } from "react-i18next";

const GlobalStyle = createGlobalStyle`
@font-face {
    font-family: 'Pretendard';
    src: url('/fonts/pretendard/Pretendard-Thin.subset.woff2') format('woff2');
    font-weight: 100;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard';
    src: url('/fonts/pretendard/Pretendard-Light.subset.woff2') format('woff2');
    font-weight: 300;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard';
    src: url('/fonts/pretendard/Pretendard-Medium.subset.woff2') format('woff2');
    font-weight: 500;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard';
    src: url('/fonts/pretendard/Pretendard-Regular.subset.woff2') format('woff2');
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: 'Pretendard';
    src: url('/fonts/pretendard/Pretendard-SemiBold.subset.woff2') format('woff2');
    font-weight: 600;
    font-display: swap;
}
* {
    margin:0;
    padding:0;
    font:inherit;
}
*, *::before, *::after {
    box-sizing:border-box;
}
html {
    font-size: 62.5%;
    -moz-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-size-adjust: auto;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
img, video, svg, picture {
    display: block;
    height: auto;
    max-width: 100%;
}
body {
    font-size: 1.6rem;
    color: #333b48;
    overflow-x: hidden;
    font-family: "Pretendard", pretendard, -apple-system, BlinkMacSystemFont, 
    system-ui, Roboto, "Helvetica Neue", "Hiragino Sans", "Apple SD Gothic Neo",  
    "Noto Sans KR", "Malgun Gothic", Osaka, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", sans-serif;
    letter-spacing:-0.132px;
}
a {
    text-decoration: none;
    cursor: pointer;
}
button {
    border: none;
    cursor: pointer;
    background-color: transparent;
}
sup {
    line-height: 1;
    vertical-align: 0px;
}
ul, ol, li {
    list-style:none; 
}
input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
input::-ms-clear {
    display: none;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
input[type='number'] {
    -moz-appearance: textfield;
}
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
select::-ms-expand {
    display: none;
}
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
::-webkit-scrollbar-thumb {
    background: #6e6e6e;
    border-radius: 10px;
}
.blind {
    position: absolute; 
    clip: rect(0 0 0 0); 
    width: 1px; 
    height: 1px; 
    margin: -1px; 
    overflow: hidden;
}
`;

function RootLayoutComponents({ children }) {
    const { i18n } = useTranslation();
    const currentLng = i18n.language === "jp" ? "ja" : i18n.language === "ko" ? "ko" : "ja";

    return (
        <>
            <Helmet>
                <html lang={currentLng} />
                <title>Photomag</title>
            </Helmet>
            <GlobalStyle />
            {children}
        </>
    )
}
export default RootLayoutComponents;