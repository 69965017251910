import styled from "styled-components";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { useTranslation } from "react-i18next";

const FocusWrap = styled.section`
    .title-area {margin-bottom:60px;}
    .title {font-size:36px; font-weight:600; margin-bottom:10px;}
    .subtitle {font-size:26px; font-weight:400;}
    @media screen and (max-width: 767px) {
        .title { font-weight: 700 !important; }
    }
    .focus-slide { padding-bottom:166px; }
    .focus-slide .swiper-slide { width:365px; border-radius:20px;overflow:hidden; }
    .focus-slide .swiper-slide img { width:100%; }
    .focus-slide .swiper-slide:nth-child(odd) { transform:translateY(76px); }
    .focus-slide .swiper-pagination {left:50%; bottom:0; right:auto; width:auto; background-color:#353535; padding:14px 20px; border-radius:50px; transform:translateX(-50%); display:flex; gap:7px;}
    .focus-slide .swiper-pagination-bullet {background-color:#fff; opacity:1; transition:width .5s ease; border-radius:5px; height:5px; width:5px; margin:0 !important;}
    .focus-slide .swiper-pagination-bullet-active {width:44px;}
`;

export default function Focus() {
    const { t } = useTranslation();
    return (
        <FocusWrap className="focus">
            <div className="inner">
                <div className="title-area">
                    <h2 className="title">Focus on the Finer Times</h2>
                    <h2 className="subtitle">{t("mainPage.focusEx")}</h2>
                </div>
            </div>
            <Swiper
                className="focus-slide"
                spaceBetween={40}
                slidesPerView={"auto"}
                pagination={{ clickable: true }}
                speed={500}
                initialSlide={2}
                centeredSlides={true}
                modules={[Pagination]}
            >
                <div className="swiper-wrapper">
                    {Array.from({ length: 8 }, (_, index) => (
                        <SwiperSlide key={index}>
                            <img 
                                src={`${process.env.REACT_APP_CDN_URL}/home/focus${index + 1}.webp`}
                                alt={`Photomag Focus ${index + 1}`}
                                width={365}
                                height={365}
                            />
                        </SwiperSlide>
                    ))}
                </div>
            </Swiper>
        </FocusWrap>
    )
}