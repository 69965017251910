import styled from "styled-components"
import logo1 from "../../assets/images/brand/logo_1.svg";
import logo2 from "../../assets/images/brand/logo_2.svg";
import colorImg from "../../assets/images/brand/color.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import { Navigation } from "swiper/modules";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import MetaTag from "../../layout/MetaTag";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const Container = styled.div`
    .logo-area {
        display:flex;gap:10%;align-items:center;justify-content:center;
        flex-wrap:wrap;
        img:nth-child(1) {width:423px;}
        img:nth-child(2) {height:166px;}
    }
    .color-area {display:flex;gap:10%;align-items:center;justify-content:center;}
    .color-item1 {display:block;border-radius:8px;background:url(${colorImg}) no-repeat center;width:282px;height:282px;background-color:#2B2B3D;background-size: 60%;background-position-x:56%;}
    .color-item2 {display:block;border-radius:8px;background:url(${colorImg}) no-repeat center;width:282px;height:282px;background-color:#7152EE;background-size: 60%;background-position-x:56%;}
    .color-item3 {display:block;border-radius:8px;background:url(${colorImg}) no-repeat center;width:282px;height:282px;background-color:#B474DF;background-size: 60%;background-position-x:56%;}
    .brand-desc {font-size:18px;margin-top:20px;line-height:1.5;}
    @media screen and (max-width: 1024px) {
        .logo-area img:nth-child(1) {width:300px;}
        .logo-area img:nth-child(2) {height:126px;}
    }
    @media screen and (max-width: 767px) {
        .logo-area {gap:40px;flex-direction:column;height:280px;}
        .logo-area img:nth-child(1) {width:240px;}
        .logo-area img:nth-child(2) {height:106px;}
        .frame-area, .fish-area {justify-content:center;}
        .brand-desc {font-size:16px;margin-top:14px;}
        .color-area {gap:14px;height:240px;}
        .color-item1, 
        .color-item2, 
        .color-item3 {width:90px;height:90px;}
        .frame-area, .fish-area {gap:34px 24px;}
        .frame-item img, .fish-item img {margin-bottom:12px !important;height:130px !important;}
        .interior-slide {height:30vh !important;}
        .page-title2 {font-size:24px !important;margin-bottom:30px !important;}
        .page-text { word-break: unset !important; }
    }
    .swiper-button-next, 
    .swiper-button-prev {
        display: none;
    }
`;
const Vision = styled.div`
    border-radius:18px;overflow:hidden;
    background:url(${process.env.REACT_APP_CDN_URL}/brand/vision.webp) no-repeat center/cover;
    padding:60px 20px;text-align:center;
    strong {
        display:block;text-align:center;color:#fff;font-size:26px;
        font-weight:600;padding-bottom:10px;
        em {font-weight:100;}
    }
    span {
        display:block;text-align:center;color:#fff;font-weight:600;
        font-size:18px;
    }
    @media screen and (max-width: 767px) {
        span { font-size: 15px !important; }
    }
`;
const GridBg = styled.div`
    background:#fff url(${process.env.REACT_APP_CDN_URL}/brand/grid_bg.webp) repeat;height:400px;
    border-radius: 18px;border: 1px solid #EDEDED;
`;
const FrameArea = styled.div`
    display:flex;justify-content:space-between;gap:30px 16px;flex-wrap:wrap;
    .frame-item {
        text-align:center;font-size:17px;
        img {margin-bottom:20px;height:244px;}
    }
`;
const FisheyeArea = styled.div`
    display:flex;justify-content:space-between;gap:30px 16px;flex-wrap:wrap;
    .fish-item {
        text-align:center;font-size:17px;
        img {margin-bottom:20px;height:244px;}
        &:last-child {
            width: 365px;
            div { display: flex; justify-content: center; }
        }
    }
    @media screen and (max-width: 767px) {
        .fish-item:last-child {
            width: auto;
        }
    }
`;
const SlideWrap = styled.div`
    position:relative;
    .interior-slide {
        border-radius:16px;height:520px;
        img {height:100%;width:100%;object-fit:cover;}
    }
    .interior-slide-prev {
        position:absolute; 
        left:-26px; top:50%; 
        display:flex; 
        align-items:center; 
        justify-content:center; 
        font-size:24px; 
        box-shadow:1px 1px 5.9px 0px rgba(0, 0, 0, 0.06); 
        transform:translateY(-50%); 
        width:52px; 
        height:52px; 
        border-radius:100px; 
        background-color:#fff; 
        z-index:1;
        cursor: pointer;
        transition: transform 0.2s ease, background-color 0.2s ease, color 0.2s ease;
        svg {
            font-size: 1.3em;
            color: inherit;
        }
        &:hover {
            background-color:#343434; 
            color:#fff;
        }
    }
    .interior-slide-next {
        position:absolute; 
        right:-26px; top:50%; 
        display:flex; 
        align-items:center; 
        justify-content:center; 
        font-size:24px; 
        box-shadow:1px 1px 5.9px 0px rgba(0, 0, 0, 0.06); 
        transform:translateY(-50%); 
        width:52px; 
        height:52px; 
        border-radius:100px; 
        background-color:#fff; 
        z-index:1;
        cursor: pointer;
        transition: transform 0.2s ease, background-color 0.2s ease, color 0.2s ease;
        svg {
            font-size: 1.3em;
            color: inherit;
        }
        &:hover {
            background-color:#343434; 
            color:#fff;
        }
    }
`;

export default function Brand() {
    const { t } = useTranslation();
    const prevRef = useRef();
    const nextRef = useRef();
    useEffect(() => {
        if (prevRef.current && nextRef.current) {
            const swiperInstance = document.querySelector('.interior-slide').swiper;
            swiperInstance.params.navigation.prevEl = prevRef.current;
            swiperInstance.params.navigation.nextEl = nextRef.current;
            swiperInstance.navigation.init();
            swiperInstance.navigation.update();
        }
    }, [prevRef, nextRef]);

    const Frames = [{ cut: "4CUT" }, { cut: "6CUT" }, { cut: "4CUT" }, { cut: "4CUT" }, { cut: "5CUT" }];
    const Fisheyes = [{ cut: "3CUT" }, { cut: "4CUT" }, { cut: "4CUT" }, { cut: "4CUT" }, { cut: "6CUT" }];

    return (
        <>
            <MetaTag
                title="ブランド - Photomag"
                description="Photomagで平凡な日常をマガジンのように素敵な瞬間に残してみましょう。"
                preload={`${process.env.REACT_APP_CDN_URL}/home/main1.webp`}
            />
            <Container className="container brand">
                <div className="page-header">
                    <div className="page-header-bg">
                        <img
                            srcSet={`
                                ${process.env.REACT_APP_CDN_URL}/home/main1-320.webp 320w,
                                ${process.env.REACT_APP_CDN_URL}/home/main1-768.webp 768w,
                                ${process.env.REACT_APP_CDN_URL}/home/main1-1400.webp 1400w,
                                ${process.env.REACT_APP_CDN_URL}/home/main1.webp 1920w
                            `}
                            sizes="(max-width: 320px) 320px, 
                                   (max-width: 768px) 768px, 
                                   (max-width: 1400px) 1400px, 
                                   1920px"
                            src={`${process.env.REACT_APP_CDN_URL}/home/main1.webp`}
                            alt="Photomag brand logo"
                            width={1531}
                            height={440}
                            loading="eager"
                        />
                    </div>
                    <div className="inner">
                        <h2 className="page-title">{t("common.brand")}</h2>
                        <p className="page-text">{t("brandPage.title")}</p>
                    </div>
                </div>
                <section>
                    <div className="inner">
                        <h3 className="page-title2">{t("brandPage.brandVision")}</h3>
                        <Vision>
                            <strong>Photo <em>X</em> Magazine</strong>
                            <span>{t("brandPage.visionContent")}</span>
                        </Vision>
                    </div>
                </section>
                <section>
                    <div className="inner">
                        <h3 className="page-title2">{t("brandPage.brandLogo")}</h3>
                        <GridBg className="logo-area">
                            <img src={logo1} alt="Photomag brand 로고1" />
                            <img src={logo2} alt="Photomag brand 로고2" />
                        </GridBg>
                        <p className="brand-desc">BI Identity : {t("brandPage.brandLogoEx")}</p>
                    </div>
                </section>
                <section>
                    <div className="inner">
                        <h3 className="page-title2">{t("brandPage.brandColor")}</h3>
                        <GridBg className="color-area">
                            <div className="color-item1"></div>
                            <div className="color-item2"></div>
                            <div className="color-item3"></div>
                        </GridBg>
                        <p className="brand-desc">Signature : {t("brandPage.brandColorEx")}</p>
                    </div>
                </section>
                <section>
                    <div className="inner">
                        <h3 className="page-title2">{t("brandPage.frames")}</h3>
                        <FrameArea className="frame-area">
                            {Frames.map((frame, index) => (
                                <div className="frame-item" key={index}>
                                    <img
                                        src={`${process.env.REACT_APP_CDN_URL}/brand/frame_${index + 1}.webp`}
                                        alt={`Photomag ${frame.cut} (1+1)`}
                                        loading="lazy"
                                    />
                                    <span>{frame.cut} (1+1)</span>
                                </div>
                            ))}
                        </FrameArea>
                    </div>
                </section>
                <section>
                    <div className="inner">
                        <h3 className="page-title2">{t("brandPage.fisheyes")}</h3>
                        <FisheyeArea className="fish-area">
                            {Fisheyes.map((fisheye, index) => (
                                <div className="fish-item" key={index}>
                                    <div>
                                        <img
                                            src={`${process.env.REACT_APP_CDN_URL}/brand/fisheye_${index + 1}.webp`}
                                            alt={`Photomag ${fisheye.cut} (1+1)`}
                                            loading="lazy"
                                        />
                                    </div>
                                    <div>
                                        <span>{fisheye.cut} (1+1)</span>
                                    </div>
                                </div>
                            ))}
                        </FisheyeArea>
                    </div>
                </section>
                <section>
                    <div className="inner">
                        <h3 className="page-title2">{t("brandPage.interior")}</h3>
                        <SlideWrap>
                            <Swiper
                                className="interior-slide"
                                speed={500}
                                slidesPerView={"auto"}
                                centeredSlides={true}
                                navigation={{
                                    prevEl: prevRef.current,
                                    nextEl: nextRef.current,
                                }}
                                onBeforeInit={(swiper) => {
                                    swiper.params.navigation.prevEl = prevRef.current;
                                    swiper.params.navigation.nextEl = nextRef.current;
                                }}
                                modules={[Navigation]}
                            >
                                <div className="swiper-wrapper">
                                    {Array.from({ length: 4 }, (_, index) => (
                                        <SwiperSlide key={index}>
                                            <img
                                                srcSet={`
                                                    ${process.env.REACT_APP_CDN_URL}/brand/interior_${index + 1}-320.webp 320w,
                                                    ${process.env.REACT_APP_CDN_URL}/brand/interior_${index + 1}-768.webp 768w,
                                                    ${process.env.REACT_APP_CDN_URL}/brand/interior_${index + 1}-1400.webp 1400w,
                                                    ${process.env.REACT_APP_CDN_URL}/brand/interior_${index + 1}.webp 1920w
                                                `}
                                                sizes="(max-width: 320px) 320px, 
                                                       (max-width: 768px) 768px, 
                                                       (max-width: 1400px) 1400px, 
                                                       1920px"
                                                src={`${process.env.REACT_APP_CDN_URL}/brand/interior_${index + 1}.webp`}
                                                alt={`Photomag brand slide${index + 1}`}
                                                loading="lazy"
                                            />
                                        </SwiperSlide>
                                    ))}
                                </div>
                            </Swiper>
                            <div ref={prevRef} className="interior-slide-prev">
                                <ChevronLeftIcon />
                            </div>
                            <div ref={nextRef} className="interior-slide-next">
                                <ChevronRightIcon />
                            </div>
                        </SlideWrap>
                        <p className="brand-desc">Concept : {t("brandPage.interiorEx")}</p>
                    </div>
                </section>
            </Container>
        </>
    )
}