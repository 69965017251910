import styled from "styled-components";
import { useTranslation } from "react-i18next";
// import CustomBreadcrumbs from "../../layout/CustomBreadcrumbs";
import MetaTag from "../../layout/MetaTag";
import { useEffect, useState } from "react";

const Container = styled.div`
    @media screen and (max-width: 767px) {
        .page-title2 {font-size:24px !important;margin-bottom:30px !important;}
        .page-text {word-break: unset !important;}
    }
    @media screen and (max-width: 1024px) {
        .gallery-list {grid-template-columns: repeat(3, 1fr);}
    }
    @media screen and (max-width: 1024px) {
        .gallery-list {grid-template-columns: repeat(3, 1fr);}
    }
    @media screen and (max-width: 767px) {
        .gallery-list {grid-template-columns: repeat(2, 1fr);gap:14px;margin:0 0 80px;}
        .gallery-list .gallery-item img {max-width:80%;max-height:80%;}
    }
`;
const GalleryList = styled.div`
    margin-top:40px;margin-bottom:80px;display:grid;
    grid-template-columns: repeat(4, 1fr);gap:20px;
    .gallery-item {
        border-radius: 18px;padding-bottom:100%;
        border: 1px solid rgba(255, 255, 255, 0.20);position:relative;
        background:url(${process.env.REACT_APP_CDN_URL}/gallery/gallery_item_bg.webp) lightgray 50% / cover;
        display:flex;align-items:center;justify-content:center;
        img {
            position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);
            max-width:90%;max-height:90%;object-fit: contain; 
        }
    }
`;

export default function Gallery() {
    const { t } = useTranslation();

    const [widthStyle, setWidthStyle] = useState("69%");

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 767) {
                setWidthStyle("61%");
            } else {
                setWidthStyle("67%");
            }
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <>
            <MetaTag
                title="ギャラリー - Photomag"
                description="PhotomagでMZ世代が好む様々なフレームをご覧いただけます。"
                preload={`${process.env.REACT_APP_CDN_URL}/gallery/sub_header_bg.webp`}
            />
            <Container className="container">
                <section>
                    <div className="page-header">
                        <div className="page-header-bg">
                            <img
                                srcSet={`
                                ${process.env.REACT_APP_CDN_URL}/gallery/sub_header_bg-320.webp 320w,
                                ${process.env.REACT_APP_CDN_URL}/gallery/sub_header_bg-768.webp 768w,
                                ${process.env.REACT_APP_CDN_URL}/gallery/sub_header_bg-1400.webp 1400w,
                                ${process.env.REACT_APP_CDN_URL}/gallery/sub_header_bg.webp 1920w
                            `}
                                sizes="(max-width: 320px) 320px, 
                                   (max-width: 768px) 768px, 
                                   (max-width: 1400px) 1400px, 
                                   1920px"
                                src={`${process.env.REACT_APP_CDN_URL}/gallery/sub_header_bg.webp`}
                                alt="Photomag gallery logo"
                                width={1531}
                                height={440}
                                loading="eager"
                            />
                        </div>
                        <div className="inner">
                            <h2 className="page-title">{t("common.gallery")}</h2>
                            <p className="page-text">{t("galleryPage.title")}</p>
                        </div>
                    </div>
                    {/* <CustomBreadcrumbs /> */}
                    <div className="inner">
                        <h3 className="page-title2">{t("galleryPage.eventFrame")}</h3>
                        <GalleryList className="gallery-list">
                            {Array.from({ length: 18 }, (_, index) => (
                                <div className="gallery-item" key={index}>
                                    <img
                                        src={`${process.env.REACT_APP_CDN_URL}/gallery/gallery_event_${index + 1}.webp`}
                                        alt={`Photomag gallery_event_${index + 1}`}
                                        width={312}
                                        height={289}
                                        loading="lazy"
                                        fetchpriority="low"
                                        style={index === 9 ? { width: widthStyle } : undefined}
                                    />
                                </div>
                            ))}
                        </GalleryList>
                        <h3 className="page-title2">{t("galleryPage.stickerFrame")}</h3>
                        <GalleryList className="gallery-list">
                            {Array.from({ length: 10 }, (_, index) => (
                                <div className="gallery-item" key={index}>
                                    <img
                                        src={`${process.env.REACT_APP_CDN_URL}/gallery/gallery_sticker_${index + 1}.webp`}
                                        alt={`Photomag gallery_sticker_${index + 1}`}
                                        width={217}
                                        height={300}
                                        loading="lazy"
                                        fetchpriority="low"
                                    />
                                </div>
                            ))}
                        </GalleryList>
                    </div>
                </section>
            </Container>
        </>
    )
}